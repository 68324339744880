<template>
  <div>
    <div class="flex justify-between mb-4">
      <h1 class="">Log</h1>

      <t-button @click="dumpLog" variant="secondary" class="">
        <fa icon="download"></fa>
        Log herunterladen
      </t-button>
    </div>

    <div v-if="requests && requests.length > 0">
      <p class="text-gray-dark mb-4">Hier befinden sich die Daten aller Änderungen, die Sie versucht haben
        durchzuführen. Falls Daten verloren gehen sollten, können Sie diese von hier aus erneut ausführen. Nach einer
        Woche werden die Einträge entfernt.</p>

      <t-card v-for="(request, index) in requests" :key="request.id" class="border-t-0">
        <div slot="header" class="flex justify-between">
          <div>
            <h1>{{ getActionString(request) }}</h1>
            <div class="max-w-2xl text-sm text-gray-darkest">
              <p class="mt-1">
              <span>
                <fa :icon="['far', 'calendar']"></fa>
                {{ getTimeString(request.timestamp) }}
              </span>
              </p>
            </div>
          </div>
          <div class="flex flex-col justify-between">
            <router-link :to="'/log/' + index">
              <t-button variant="secondary" style="padding: .25rem .5rem">
                <fa icon="trash-restore-alt" class="mr-0"></fa>
              </t-button>
            </router-link>
          </div>
        </div>
      </t-card>
    </div>

    <p class="text-gray-dark mb-4" v-else>
      Keine Aktionen im Log
    </p>
  </div>
</template>

<script>
import api from '@/api'
import store from '@/store'
import {dumpLog} from "../main";

export default {
  name: 'Edit.vue',
  data: function () {
    return {
      requests: store.state.requestLog
    }
  },
  computed: {},
  methods: {
    dumpLog () {
      if (store.state.requestLog.length === 0) {
        this.$emit('alert', 'warning', "Log ist leer.")
        return
      }
      dumpLog(null, true, false)
    },
    getActionString (request) {
      const requestBody = JSON.parse(request.body)
      const auditNumber = requestBody?.number
      if (request.method === 'POST') {
        if (request.url === store.state.apiUrl + '/modules/audits/resources') {
          return `Prüfung erstellt${auditNumber ? ': ' + auditNumber : ''}`
        }
      } else if (request.method === 'PUT') {
        return `Prüfung bearbeitet${auditNumber ? ': ' + auditNumber : ''}`
      } else if (!request.url) {
        return 'Fehlerhafter Eintrag'
      }
      return 'HTTP-Anfrage nach ' + request.url
    },
    getTimeString (timestamp) {
      const date = new Date(timestamp)
      return date.toLocaleString()
    },
  },
  async created () {

  },
  mixins: [api]
}
</script>

<style scoped>

</style>
