<template>
  <div class="mx-auto lg:w-1/3 h-full flex flex-wrap items-center p-4">
    <img class="mx-auto" alt="logo" src="/img/logo.svg">
    <swiper ref="swiper" :options="swiperOptions">
      <swiper-slide>
        <form
            @submit="login"
            class="w-full h-auto px-3"
        >
          <t-input-group
              label="Benutzername"
          >
            <t-input
                v-model="loginData.username"
                :variant="inputVariant"
            />
          </t-input-group>

          <t-input-group
              label="Passwort"
          >
            <t-input
                v-model="loginData.password"
                :variant="inputVariant"
                type="password"
            />
          </t-input-group>
          <t-alert v-for="alert in alerts" :key="alert" class="mb-4" :show="inputVariant === 'danger'" variant="danger" :dismissible="false">
            {{ alert }}
          </t-alert>
          <t-button type="submit">
            Einloggen
          </t-button>
        </form>
      </swiper-slide>
      <swiper-slide>
        <form @submit="login" class="px-3">
          <t-input-group>
            <template v-slot:label><fa icon="arrow-left" @click="prev" class="cursor-pointer"></fa> 2FA-Code</template>
            <t-input
                v-model="loginData.code"
                :variant="inputVariant"
                type="text"
            />
          </t-input-group>
          <div class="mb-3">
            <a href="javascript:void(0)" @click="loginData.resendCode = true; login($event)">
              <fa icon="redo"></fa>
              Code erneut abschicken
            </a>
          </div>
          <t-alert v-for="alert in alerts" :key="alert" class="mb-4" :show="inputVariant === 'danger'" variant="danger" :dismissible="false">
            {{ alert }}
          </t-alert>
          <t-button type="submit">
            Einloggen
          </t-button>
        </form>
      </swiper-slide>
    </swiper>
  </div>
</template>

<script>
import store from '@/store/index'
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'

export default {
  name: 'Login',
  components: {
    Swiper,
    SwiperSlide
  },
  data: () => ({
    alerts: [],
    inputVariant: "",
    loginData: {
      username: store.state.debug && store.state.debug !== "false" ? "dev@ki-bro.com" : "",
    },
    swiperOptions: {
      allowTouchMove: false,
    },
    twoFactor: {
      required: false,
      code: null,
    },
  }),
  methods: {
    login (e) {
      e.preventDefault()
      let url = this.$store.state.apiUrl
      this.inputVariant = "loading"

      fetch(url + '/login', {
        body: JSON.stringify(this.loginData),
        headers: {
          'Content-Type': 'application/json',
        },
        method: 'POST',
      })
        .then((response) => {
          this.loginData.resendCode = false
          if (response.status === 409 || response.status === 503 || response.status === 401) {
            throw new Error('' + response.status)
          }
          return response
        })
        .then(response => response.json())
        .then((response) => {
          this.inputVariant = ""
          const data = response.data
          if (!data.authenticated && data.twoFactor) {
            this.twoFactor.required = true
            this.next()
          }
          if (!response.success) {
            const error = new Error()
            error.errors = response.errors
            throw error
          }
          if (data.authenticated) {
            let accessToken = data.accessToken
            if (accessToken !== undefined) {
              this.inputVariant = "success"
              store.commit('setUser', {
                accessToken: accessToken,
                email: data.user.email,
                id: data.user.id,
                name: data.user.brezel_name,
                module: {
                  id: data.user.module_id,
                  identifier: null,
                }
              })
              store.commit('setSignature', data.user.signature)
              this.$emit('login')
            }
          }
        })
        .catch(e => {
          this.loginData.resendCode = false
          this.inputVariant = "danger"
          this.alerts = []
          if (e.errors) {
            e.errors.forEach(error => {
              this.alerts.push(error.message)
            })
          }
          if (e.message === "401") {
            this.alerts.push("Die eingegebenen Daten sind nicht korrekt. Bitte versuchen Sie es erneut.")
          }
          console.error(e)
        })

    },

    prev () {
      this.$refs.swiper.$swiper.slidePrev()
      this.loginData.code = null
    },

    next () {
      this.$refs.swiper.$swiper.slideNext()
    },
  }
}
</script>

<style scoped>

</style>
